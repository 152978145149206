<template>
    <div class="layout-default">
        <my-header></my-header>

        <nuxt />

        <el-backtop 
            class="backtop-layout"
            target="#__nuxt"
        ></el-backtop>

        <my-footer></my-footer>
    </div>
</template>

<script>
import CONFIG from '@config/index';
import MyHeader from '@components/sys/my-header/index';
import MyFooter from '@components/sys/my-footer';
import appNode from '@js/app-node';

export default {
    components: {
        MyHeader,
		MyFooter,
    },
    data() {
        return {
			
        };
    },
    methods: {
        dealCenterClick(){
            window.open(`/deal-nav`)
        }
    },
    
    mounted: function() {
		
    }
};
</script>

<style scoped lang="scss">
.layout-default {
    background-color: #fff;
    width: 100%;
}

.backtop-layout{
    width: 60px;
    height: 60px;
}
</style>


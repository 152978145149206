import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _2d4ce305 = () => interopDefault(import('..\\pages\\about\\index.vue' /* webpackChunkName: "pages/about/index" */))
const _7e9a7062 = () => interopDefault(import('..\\pages\\business\\index.vue' /* webpackChunkName: "pages/business/index" */))
const _0f589a9a = () => interopDefault(import('..\\pages\\download\\index.vue' /* webpackChunkName: "pages/download/index" */))
const _e69bbf94 = () => interopDefault(import('..\\pages\\guide\\index.vue' /* webpackChunkName: "pages/guide/index" */))
const _3fbf676f = () => interopDefault(import('..\\pages\\news\\index.vue' /* webpackChunkName: "pages/news/index" */))
const _3297e59f = () => interopDefault(import('..\\pages\\register\\index.vue' /* webpackChunkName: "pages/register/index" */))
const _25f2a91e = () => interopDefault(import('..\\pages\\business\\detail.vue' /* webpackChunkName: "pages/business/detail" */))
const _7fc57146 = () => interopDefault(import('..\\pages\\business\\detail-sd.vue' /* webpackChunkName: "pages/business/detail-sd" */))
const _50809604 = () => interopDefault(import('..\\pages\\news\\detail.vue' /* webpackChunkName: "pages/news/detail" */))
const _1d30011a = () => interopDefault(import('..\\pages\\business\\components\\file-download.vue' /* webpackChunkName: "pages/business/components/file-download" */))
const _10cd63af = () => interopDefault(import('..\\pages\\business\\components\\link-paper.vue' /* webpackChunkName: "pages/business/components/link-paper" */))
const _123ff1a4 = () => interopDefault(import('..\\pages\\business\\components\\search-bar.vue' /* webpackChunkName: "pages/business/components/search-bar" */))
const _32a7b249 = () => interopDefault(import('..\\pages\\business\\components\\table-hxrgs.vue' /* webpackChunkName: "pages/business/components/table-hxrgs" */))
const _8a37a19c = () => interopDefault(import('..\\pages\\register\\components\\btn-yzm.vue' /* webpackChunkName: "pages/register/components/btn-yzm" */))
const _7b285fb1 = () => interopDefault(import('..\\pages\\register\\components\\captcha.vue' /* webpackChunkName: "pages/register/components/captcha" */))
const _bf856240 = () => interopDefault(import('..\\pages\\register\\components\\input-yzm.vue' /* webpackChunkName: "pages/register/components/input-yzm" */))
const _8852b1e2 = () => interopDefault(import('..\\pages\\register\\components\\tos.vue' /* webpackChunkName: "pages/register/components/tos" */))
const _0e4949a2 = () => interopDefault(import('..\\pages\\index.vue' /* webpackChunkName: "pages/index" */))
const _f41e3708 = () => interopDefault(import('..\\pages\\index\\sections\\banner.vue' /* webpackChunkName: "pages/index/sections/banner" */))
const _882325e0 = () => interopDefault(import('..\\pages\\index\\sections\\business.vue' /* webpackChunkName: "pages/index/sections/business" */))
const _d89a2b2a = () => interopDefault(import('..\\pages\\index\\sections\\enters.vue' /* webpackChunkName: "pages/index/sections/enters" */))
const _b914daba = () => interopDefault(import('..\\pages\\index\\sections\\news.vue' /* webpackChunkName: "pages/index/sections/news" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _2d4ce305,
    alias: "/about/index.html",
    name: "about"
  }, {
    path: "/business",
    component: _7e9a7062,
    alias: "/business/index.html",
    name: "business"
  }, {
    path: "/download",
    component: _0f589a9a,
    alias: "/download/index.html",
    name: "download"
  }, {
    path: "/guide",
    component: _e69bbf94,
    alias: "/guide/index.html",
    name: "guide"
  }, {
    path: "/news",
    component: _3fbf676f,
    alias: "/news/index.html",
    name: "news"
  }, {
    path: "/register",
    component: _3297e59f,
    alias: "/register/index.html",
    name: "register"
  }, {
    path: "/business/detail",
    component: _25f2a91e,
    alias: "/business/detail/index.html",
    name: "business-detail"
  }, {
    path: "/business/detail-sd",
    component: _7fc57146,
    alias: "/business/detail-sd/index.html",
    name: "business-detail-sd"
  }, {
    path: "/news/detail",
    component: _50809604,
    alias: "/news/detail/index.html",
    name: "news-detail"
  }, {
    path: "/business/components/file-download",
    component: _1d30011a,
    alias: "/business/components/file-download/index.html",
    name: "business-components-file-download"
  }, {
    path: "/business/components/link-paper",
    component: _10cd63af,
    alias: "/business/components/link-paper/index.html",
    name: "business-components-link-paper"
  }, {
    path: "/business/components/search-bar",
    component: _123ff1a4,
    alias: "/business/components/search-bar/index.html",
    name: "business-components-search-bar"
  }, {
    path: "/business/components/table-hxrgs",
    component: _32a7b249,
    alias: "/business/components/table-hxrgs/index.html",
    name: "business-components-table-hxrgs"
  }, {
    path: "/register/components/btn-yzm",
    component: _8a37a19c,
    alias: "/register/components/btn-yzm/index.html",
    name: "register-components-btn-yzm"
  }, {
    path: "/register/components/captcha",
    component: _7b285fb1,
    alias: "/register/components/captcha/index.html",
    name: "register-components-captcha"
  }, {
    path: "/register/components/input-yzm",
    component: _bf856240,
    alias: "/register/components/input-yzm/index.html",
    name: "register-components-input-yzm"
  }, {
    path: "/register/components/tos",
    component: _8852b1e2,
    alias: "/register/components/tos/index.html",
    name: "register-components-tos"
  }, {
    path: "/",
    component: _0e4949a2,
    alias: "/index.html",
    name: "index",
    children: [{
      path: "sections/banner",
      component: _f41e3708,
      name: "index-sections-banner"
    }, {
      path: "sections/business",
      component: _882325e0,
      name: "index-sections-business"
    }, {
      path: "sections/enters",
      component: _d89a2b2a,
      name: "index-sections-enters"
    }, {
      path: "sections/news",
      component: _b914daba,
      name: "index-sections-news"
    }]
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}

<template>
    <header class="header" :class="{fixed: fixed}">

        <div class="content">
            <div class="head-top">
                <div class="head_logo">
                    <img :src="head.logourl" v-if="head.logourl">
                    <a href="/" v-if="!head.logourl">
                        {{head.title}}
                    </a>
                </div>

                <!-- <div class="loginReg">
                    <el-dropdown @command="handleCommand" placement="bottom">
                        <a class="login">
                            登录
                        </a>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item :command="head.dljgLoginUrl">代理登录</el-dropdown-item>
                            <el-dropdown-item :command="head.yezhuLoginUrl">采购人登录</el-dropdown-item>
                            <el-dropdown-item :command="head.tbrLoginUrl">投标登录</el-dropdown-item>
                            <el-dropdown-item :command="head.jmrLoginUrl">竞买人登录</el-dropdown-item>
                            <el-dropdown-item :command="head.zjLoginUrl">评标登录</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                    
                    <a target="_blank" href="/register" class="reg">注册</a>
                </div>

                <div class="search">
                    <el-input 
                        placeholder="请输入公告名称" 
                        v-model="searchTxt"
                        style="width:300px;height:40px;float:left" 
                        class="input-with-select">
                            <el-button 
                                slot="append" 
                                @click="search"
                                icon="el-icon-search">
                            </el-button>
                    </el-input>
                    
                </div> -->
               
            </div>
            
            <div class="nav-list header-com">
                <template v-for="(item, index) in $store.state.config.nav">
                    <a 
                    class="nav-item" 
                    :key="index"
                    :href="item.href+'?featurecode='+head.planname"
                    :class="{active: isActive(item)}"
                    @click="clickHandler($event, item.click)"
                    >
                        {{item.title}}
                    </a>
                </template>
            </div>
       
        </div>

        <slot></slot>
    </header>
</template>

<script>
import CONFIG from '@config/config';
import { request } from 'http';
import appNode from '@js/app-node';

export default {
    components: {
        
    },
    props: {
        theme: {
            type: String,
            default: ''
        },
        fixed: {
            type: Boolean,
            default: false,
        },
    },
    data () {
        return {
            useSearchDeal: false,   //是否使用 (查交易中心、查公告、查企业) 搜索组件
            searchText:'',
            logo:'',
            head:CONFIG.head,
            searchTxt:'',
        }
    },
    computed: {
        
    },
    methods: {
        isActive(item) {
            var path = this.$route.path,
                href = item.href,
                activeReg = item.activeReg;

            if (href === path) {
                return true;
            }

            if (path === href) {
                return true;
            }

            if (activeReg && activeReg.test(path)) {
                return true;
            }

            return false;
        },
        clickHandler(e, cb) {
            if (cb) {
                e.preventDefault();
                
                var fun = new Function(cb);
                fun.call(this);
            }
        },

        handleCommand(command){
            window.open(command)
        },
        // 搜索
        search(){
            window.location.href = '/business?featurecode=nccqjy&searchcontent='+this.searchTxt
        }
    },
    mounted: function() {
        // if($_plat_config.title){
        //     CONFIG.head = $_plat_config;
        //     this.title = CONFIG.head.title;
        //     this.tel = CONFIG.head.tel;
        //     this.featurecode = CONFIG.head.planname;
        // }
        
    },
   created() {
        if(this.getQuery('searchcontent')){
            this.searchTxt =  this.getQuery('searchcontent')
        }

    },
}
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
    @import '@css/var.scss';
    
	.header{
		overflow: hidden;
		cursor: default;
        background: white;
        .heads{
            width: 100%;
            height:40px;
            line-height: 40px;
            background: #fff;
            border-bottom: 1px solid #e6e6e6;
        }
        .heads_con{
            width: 1200px;
            margin:0 auto;
        }
        .head_a{
            width: 100%;
            height:90px;
            background: #fff;
        }
        .content{

            .head-top{
                overflow: hidden;
                margin:10px 0;
                .head_logo{
                    min-width: 165px;
                    float:left;
                    font-size: 22px;
                    font-weight: 700;
                }
                .head_logo a{
                    color: #61ae6a;
                }
            }
        }
        &.fixed{
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            z-index: 10000;
            min-width: 1200px;
        }

		.header-com{ 
			float:left;
		}

	

		.nav-list{
            background: #61ae6a;
            height: 46px;
			font-size: 16px;
			overflow: hidden;
            width: 100%;
			.nav-item{
				float: left;
                width: 200px;
                height: 46px;
                line-height: 46px;
				cursor: pointer;
				color: #fff;
                text-align: center;
                &:hover{
					background: #519459;
				}
			}
            .active{
                background: #519459;
            }
		}
        .search{ 
            float: right;
            margin-top: 10px;
            
        }
        .loginReg{
            margin-top: 20px;
            margin-left: 20px;
            float: right;
            .login{
                padding: 8px 15px;
                color: #61ae6a;
                border: solid 1px #61ae6a;
                border-radius: 5px;
            }
            
            .reg{
                padding: 8px 15px;
                color: #fff;
                border-radius: 5px;
                margin-left: 15px;
                background-color: #61ae6a;
            }
        }
	}

    .child-item{
        color: #333333;
    }
    .el-dropdown-menu {
        top:58px !important
    }
    .el-dropdown-menu__item:not(.is-disabled):hover, .el-dropdown-menu__item:focus{
        background: #fff; 
        color: #519459;
    }
</style>

 
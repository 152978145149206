<template>
    <footer class="footer">
        <div 
            class="foot_wrap content">
                <div class="left">
                    <span>Copyright © 2022 </span>
                    <span>{{head.title}}</span>
                    <span>All Rights Reserved</span>
                </div>
                <div class="right">
                    <span v-if="head.dwname">{{head.dwname}} | </span>
                    <span v-if="head.tel">联系电话：{{head.tel}} | </span>
                    <span v-if="head.address">地址：{{head.address}} | </span>
                    <span><a href="https://beian.miit.gov.cn/#/Integrated/index" style="color:#fff" target="_blank">备案号：{{head.icp}}</a></span>
                </div>
          
        </div>
    </footer>
</template>

<script>
    import CONFIG from '@config/config';
    export default {
     
        data: () => ({
            head:CONFIG.head
        }),
         mounted() {
           
        }
    }
</script>

<style scoped lang="scss">
	@import '@css/var.scss';

    $textColor: #949494;

	.footer{
		background:#1a1b1d;
		padding: 40px 0;
		color:white;
        margin-top: 40px;
		.foot_wrap{ 
            width: 1200px; 
            margin: 0 auto; 
            position: relative; 
           
            .left{
                float: left;
            }
            .right{
                float:right
            }
        }
      
	}
</style>
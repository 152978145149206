export default {
        head: {
            aboutus: "<p>邢台市农村产权交易平台</p>",
            address: "",
            citycode: "130500",
            guid: "AEE20DFD3BC94C2D8CB7FCF173D88137",
            icp: "冀ICP备2023027211号",
            logofileguid: "",
            logourl:"/images/logo.png",
            meta: "邢台市农村产权交易平台",
            planname: "nccqjy",
            tel: "",
            title: "邢台市农村产权交易平台",
            address:'邢台市中华大街269号',
            dwname:'邢台市供销合作社',
            dljgLoginUrl:'http://dl.xtnccq.com/otc/#/login?plan=nccqjy&mRole=agent',
            tbrLoginUrl:'http://tbr.xtnccq.com/newtbr/index.html#/login?plan=nccqjy',
            jmrLoginUrl:'http://nccqpaimai.xtnccq.com/#/login?plan=nccqjy',
            zjLoginUrl:'http://pb.xtnccq.com',
            yezhuLoginUrl:'http://dl.xtnccq.com/otc/#/login?plan=nccqjy&mRole=tender',
        },
      
    }
    